import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Layout from '../components/layout'
import StorageUnits from '../components/HomeComponents/StorageUnits'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Self Storage - Large Storage Units - Storage Facilities"
      description="Carson Bluffs Self Storage has been serving self storage needs in Fountain for nearly 20 years. Our storage facilities include large storage units."
    />
    <section className="bg-gray-800 pt-12 relative text-white">
      <StaticImage
        src="../images/storage-unit-door.jpg"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          inset: 0,
          objectFit: 'cover'
        }}
        // className="absolute h-full inset-0 object-center object-cover w-full"
        placeholder="dominantColor"
        alt="Hero image"
        width="1080"
        height="720"
        quality={90}
      />
      <div className="container mx-auto px-4 py-48 relative">
        <div className="flex flex-wrap -mx-4">
          <div className="px-4 w-full lg:w-6/12 xl:w-5/12">
            <h1 className="font-normal leading-tight mb-2 text-7xl">
              Carson Bluffs Self Storage
            </h1>
            <p className="mb-12 text-xl">
              Affordable Self Storage in the Fountain Valley. Est. 2002.
            </p>
          </div>
        </div>
      </div>
    </section>
    <StorageUnits />
    <div className="container mx-auto px-4 py-6">
      <hr />
    </div>
    <section className="py-12 text-center">
      <div className="container mx-auto px-4">
        <div className="mb-6">
          <h2 className="font-medium mb-2 text-4xl text-gray-700">
            How it works
          </h2>
        </div>
        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="p-4 w-full sm:w-6/12 lg:w-4/12">
            <div className="border px-6 py-12 xl:px-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="inline-block mb-6 text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zm-3.847-8.699a2 2 0 1 0 2.646 2.646 4 4 0 1 1-2.646-2.646z" />
                </g>
              </svg>
              <h3 className="font-medium mb-4 text-gray-800 text-xl">
                Find A Storage Unit
              </h3>
              <p>
                Let us know your storage needs and we&apos;ll match you with the
                best fit from our available units.
              </p>
            </div>
          </div>
          <div className="p-4 w-full sm:w-6/12 lg:w-4/12">
            <div className="border px-6 py-12 xl:px-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="inline-block mb-6 text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill-rule="nonzero"
                    d="M12 .5l4.226 6.183 7.187 2.109-4.575 5.93.215 7.486L12 19.69l-7.053 2.518.215-7.486-4.575-5.93 7.187-2.109L12 .5zm0 3.544L9.022 8.402 3.957 9.887l3.225 4.178-.153 5.275L12 17.566l4.97 1.774-.152-5.275 3.224-4.178-5.064-1.485L12 4.044zM10 12a2 2 0 1 0 4 0h2a4 4 0 1 1-8 0h2z"
                  />
                </g>
              </svg>
              <h3 className="font-medium mb-4 text-gray-800 text-xl">
                Read Our Reviews
              </h3>
              <p>
                See what other customers are saying about their experience at
                Carson Bluffs Self storage.
              </p>
            </div>
          </div>
          <div className="p-4 w-full sm:w-6/12 lg:w-4/12">
            <div className="border px-6 py-12 xl:px-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="inline-block mb-6 text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path d="M12 1l8.217 1.826c.457.102.783.507.783.976v9.987c0 2.006-1.003 3.88-2.672 4.992L12 23l-6.328-4.219C4.002 17.668 3 15.795 3 13.79V3.802c0-.469.326-.874.783-.976L12 1zm0 2.049L5 4.604v9.185c0 1.337.668 2.586 1.781 3.328L12 20.597l5.219-3.48C18.332 16.375 19 15.127 19 13.79V4.604L12 3.05zm4.452 5.173l1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95z" />
                </g>
              </svg>
              <h3 className="font-medium mb-4 text-gray-800 text-xl">
                Secure Your Space
              </h3>
              <p>
                Get started today. Find an affordable self storage unit in
                Fountain.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-red-600 py-16 text-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4  items-center">
          <div className="px-4 py-2 w-full lg:w-7/12">
            <h2 className="font-medium mb-4 text-4xl">
              Do You Need Self Storage Services?
            </h2>
            <p>
              Let the staff of Carson Bluffs Storage ease the stress of moving.
              If you live in Fountain, CO. Fort Carson, or the surrounding
              areas, we can assist you with your storage needs.
            </p>
          </div>
          <div className="flex flex-row justify-end px-4 py-2 w-full lg:ml-auto lg:w-auto">
            <h4>Locally Owned</h4>
          </div>
        </div>
      </div>
    </section>
    <div className="container mx-auto px-4 py-6">
      <hr />
    </div>
    <section className="bg-gray-50 py-24 text-center">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4  items-center">
          <div className="mx-auto px-4 w-full lg:w-7/12">
            <h2 className="font-medium mb-2 text-4xl text-gray-700">
              Get Your Unit
            </h2>
            <p className="mb-6">
              Talk to us about your space and moving concerns. Feel free to
              contact us at{' '}
              <a
                href="tel:+17193820999"
                className="hover:text-red-700 hover:underline text-red-600"
              >
                719-382-0999
              </a>
              . or click on the button to send us a message. We&apos;ll get back
              to you within 1 business day.
            </p>
            <Link
              to="/contact"
              className="bg-red-600 hover:bg-red-800 inline-block px-6 py-2 rounded-full text-center text-white"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-gray-900 py-12 relative text-gray-400">
      <img
        src="https://images.unsplash.com/photo-1609143739217-01b60dad1c67?ixid=MnwyMDkyMnwwfDF8c2VhcmNofDV8fHN0b3JhZ2UlMjB1bml0fGVufDB8fHx8MTYyNTE5Mzk2Nw&ixlib=rb-1.2.1q=85&fm=jpg&crop=faces&cs=srgb&w=1440&fit=max"
        className="absolute group-hover:opacity-75 h-full left-0 object-center object-cover opacity-50 top-0 w-full"
        alt="CTA image"
        width="1080"
        height="720"
      />
      <div className="container mx-auto px-4 relative">
        <div className="flex flex-wrap -mx-4">
          <div className="p-4 w-full sm:w-6/12 lg:w-3/12">
            <div className="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M4 6.143v12.824l5.065-2.17 6 3L20 17.68V4.857l1.303-.558a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V7l2-.857zm12.243 5.1L12 15.485l-4.243-4.242a6 6 0 1 1 8.486 0zM12 12.657l2.828-2.829a4 4 0 1 0-5.656 0L12 12.657z" />
                </g>
              </svg>
              <div>
                <a
                  href="https://goo.gl/maps/QCyTq2gsE3ycoAUx6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="font-medium mb-1 text-2xl text-white">
                    Click to Find Us on Google Maps
                  </h2>
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 w-full sm:w-6/12 lg:w-3/12">
            <div className="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18 17v5h-2v-5c0-4.451 2.644-8.285 6.447-10.016l.828 1.82A9.002 9.002 0 0 0 18 17zM8 17v5H6v-5A9.002 9.002 0 0 0 .725 8.805l.828-1.821A11.002 11.002 0 0 1 8 17zm4-5a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </g>
              </svg>
              <div>
                <h2 className="font-medium mb-1 text-2xl text-white">1,000+</h2>
                <p>Customers Served</p>
              </div>
            </div>
          </div>
          <div className="p-4 w-full sm:w-6/12 lg:w-3/12">
            <div className="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" />
                </g>
              </svg>
              <div>
                <h2 className="font-medium mb-1 text-2xl text-white">
                  Easy Base Access
                </h2>
                <p>Ideal For Service Members</p>
              </div>
            </div>
          </div>
          <div className="p-4 w-full sm:w-6/12 lg:w-3/12">
            <div className="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="3rem"
                height="3rem"
                className="text-red-600"
              >
                <g>
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path d="M12 1l8.217 1.826c.457.102.783.507.783.976v9.987c0 2.006-1.003 3.88-2.672 4.992L12 23l-6.328-4.219C4.002 17.668 3 15.795 3 13.79V3.802c0-.469.326-.874.783-.976L12 1zm0 2.049L5 4.604v9.185c0 1.337.668 2.586 1.781 3.328L12 20.597l5.219-3.48C18.332 16.375 19 15.127 19 13.79V4.604L12 3.05zm4.452 5.173l1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95z" />
                </g>
              </svg>
              <div>
                <h2 className="font-medium mb-1 text-2xl text-white">
                  On-Site Management
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
