import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function StorageUnits() {
  return (
    <section className="py-12">
      <div className="container mx-auto px-4">
        <div className="mb-6 text-center">
          <h2 className="font-medium mb-2 text-4xl text-gray-700">
            Our Storage Units
          </h2>
          <p>
            We have self storage units for every need, from individuals &amp;
            families to commercial storage.
          </p>
        </div>
        <div className="flex flex-wrap -mx-4  justify-center">
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/5x8-unit-illustration.png"
                className="w-full"
                alt="5x8 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      5x8 Storage Unit
                    </h3>
                    <div>
                      <span className="align-middle">
                        At only $59 per month, this unit will fit a small
                        bedroom of furniture and boxes. Contact us today for
                        availability!
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$59 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/5x10-unit-illustration.png"
                className="w-full"
                alt="5x10 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      5x10 Storage Unit
                    </h3>
                    <div>
                      <span className="align-middle">
                        At $79 per month, this unit will accommodate a typical 1
                        bedroom apartment. Contact us today for availability!
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$79 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/8x19-unit-illustration.png"
                className="w-full"
                alt="8x10 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      8x10 Storage Unit
                    </h3>

                    <div>
                      <span className="align-middle">
                        At only $99 per month, this unit will fit a standard 2
                        bedroom home, and additional storage boxes or items.
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$99 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/10x10-unit-illustration.png"
                className="w-full"
                alt="10x10 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      10 x 10 Storage Unit
                    </h3>
                    <div>
                      <span className="align-middle">
                        At only $109 per month, this unit will fit 3br home, and
                        additional storage boxes or items.
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$109 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/8x20-unit-illustration.png"
                className="w-full"
                alt="8x20 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      8x20 Storage Unit
                    </h3>

                    <div>
                      <span className="align-middle">
                        At only $135 per month, this unit will fit a 4br home,
                        and additional storage boxes or items typically. Contact
                        us today for availability!
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$135 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full p-4 w-full md:w-6/12 lg:w-4/12">
            <div className="bg-white border min-h-full shadow-xl">
              <StaticImage
                src="../../images/10x20-unit-illustration.png"
                className="w-full"
                alt="10x20 Storage Unit Fountain Colorado"
                loading="lazy"
                placeholder="tracedSVG"
                width="350"
                height="240"
                quality={80}
              />
              <div className="pb-4 pt-6 px-6">
                <div className="flex items-center justify-between py-1">
                  <div className="py-1">
                    <h3 className="font-medium text-xl hover:text-gray-900 mb-1 text-gray-700">
                      10x20 Storage Unit
                    </h3>
                    <div>
                      <span className="align-middle">
                        At only $169 per month, this unit will accommodate 5br
                        home and its belongings. Contact us today for
                        availability!
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex items-center justify-between py-1">
                  <span className="font-bold py-1">
                    <span>$169 / month</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 text-center">
          <a
            href="tel:+17193820999"
            className="bg-red-600 hover:bg-red-700 inline-block px-6 py-2 rounded-none text-center text-white w-full md:w-8/12"
          >
            Call Now
          </a>
        </div>
      </div>
    </section>
  )
}
